.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrollTable {
  max-height: 260px;
  overflow-y: scroll;
}

#content {
  text-align: center;
  vertical-align: middle
}

.ui.dropdown > .clear.icon:before {
  content: "\f00d";
  font-family: "Icons";
}
.sectionAccordion {
  overflow: hidden;
}

.sectionAccordion:after {
  background-color: #AAA;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 100%;
  left: 0.5em;
  margin-right: -100%;  
}